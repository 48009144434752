import { Box, Container, Grid, Stack, StyleProps, styled } from '@mui/material'
import BannerResults from '@assets/images/banner-results.webp'
import { Header } from '@containers/header'
import { Chip, ChipSkeleton, Skeleton, Typography } from '@components'

import { Navigate, useParams } from 'react-router-dom'
import { ResultsExport } from './results-export'
import { useProject } from '@core/api/hooks'
import ResultsActivity from './results-activity'

const styles: StyleProps = {
  root: {
    pt: 6,
    minHeight: '100vh',
    overflow: 'hidden',
  },
  banner: {
    bgcolor: 'N800',
    width: '100%',
    height: 350,
    px: 4,
    pt: 5,
    backgroundImage: `url(${BannerResults})`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  },
  bannerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  bannerProjectInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  bannerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 5,
  },
  content: {
    pt: 5,
    width: '100%',
    minHeight: '100vh',
    height: '100%',
  },
}

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  fontWeight: 'bold',
  color: theme.palette.N000,
  letterSpacing: '-1.07px',
}))

export default function Results() {
  const { projectId } = useParams() as { projectId: string }
  const { data, isLoading } = useProject(projectId)

  if (data && data.isDeleted) {
    return <Navigate to="/404" />
  }

  return (
    <Grid sx={styles.root} container>
      <Grid item xs={12}>
        <Header />
      </Grid>

      <Grid sx={styles.banner} item xs={12}>
        <Container maxWidth="xl" sx={styles.bannerContainer}>
          <Box sx={styles.bannerProjectInfo}>
            {isLoading ? (
              <Skeleton width={200} height={38} variant="rounded" />
            ) : (
              <Typography variant="h2" color="N000">
                {data?.title}
              </Typography>
            )}

            <Box sx={styles.headerLabels}>
              {isLoading ? (
                <Stack direction="row" spacing={2}>
                  <ChipSkeleton />
                  <ChipSkeleton />
                </Stack>
              ) : (
                <Stack direction="row" spacing={1}>
                  {data?.material && (
                    <Chip bgcolor="P075" textColor="N600" label={data?.material} />
                  )}
                  {data?.framework.shortName && (
                    <Chip color="primary" label={data?.framework.shortName} />
                  )}
                </Stack>
              )}
            </Box>
          </Box>

          <Box sx={styles.bannerTitle}>
            <PageTitle variant="h1">Your Tagged Materials</PageTitle>
            <ResultsExport />
          </Box>
        </Container>
      </Grid>

      <Container maxWidth="xl">
        <Grid sx={styles.content} container>
          <Grid item md={12}>
            <ResultsActivity />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  )
}
